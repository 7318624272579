body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #f7f9fb;
}

.photo-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.photo-list {
  display: grid;
  width: 1500px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 0 25px;
  grid-auto-rows: 7px;
}
@media (max-width: 1550px) {
  .photo-list {
    grid-template-columns: 1fr 1fr;
    width: 1000px;
  }
}
@media (max-width: 1050px) {
  .photo-list {
    width: 850px;
  }
}
@media (max-width: 910px) {
  .photo-list {
    width: 700px;
  }
}
@media (max-width: 780px) {
  .photo-list {
    width: 600px;
  }
}

@media (max-width: 660px) {
  .photo-list {
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.photo-list img {
  width: 475px;
  padding-top: 0.3rem;
  padding-bottom: 1.6rem;
  width: 100%;
}
@media (max-width: 1050px) {
  .photo-list img {
    width: 400px;
    width: 100%;
  }
}
@media (max-width: 910px) {
  .photo-list img {
    width: 325px;
    width: 100%;
  }
}
@media (max-width: 780px) {
  .photo-list img {
    width: 275px;
    width: 100%;
  }
}
@media (max-width: 660px) {
  .photo-list img {
    width: 100%;
  }
}

.tags {
  height: 2rem;
  margin-top: -1.8rem;
}

.tag {
  display: flex;
  align-items: center;
  max-height: 3rem;
  font-size: 1.1rem;
  padding: 2px 0 2px 0;
  background-color: hsl(210, 33%, 94%);
  color: #687864;
  font-weight: 400;
  padding-left: 0.2rem;
  line-height: 1.1;
  transform: translateY(-7%);
}
@media (max-width: 2200px) {
  .tag {
    font-size: 1.2rem;
  }
}
@media (max-width: 1520px) {
  .tag {
    font-size: 1.6rem;
  }
}
@media (max-width: 1300px) {
  .tag {
    font-size: 1.7rem;
  }
}
@media (max-width: 1050px) {
  .tag {
    font-size: 1.6rem;
  }
}
@media (max-width: 910px) {
  .tag {
    font-size: 1.5rem;
  }
}
@media (max-width: 780px) {
  .tag {
    font-size: 1.4rem;
  }
}
@media (max-width: 660px) {
  .tag {
    font-size: 1.7rem;
  }
}

.errors {
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  color: #31708b;
  /* font-weight: 300; */
  padding-bottom: 1rem;
}