.no-suggestions {
  padding: 0.4rem;
  font-size: 1.6rem;
  position: absolute;
  opacity: 0;
  animation: fadeout 5s;
  -moz-animation: fadeout 5s;
  -webkit-animation: fadeout 5s;
  -o-animation: fadeout 5s;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeout {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.suggestions {
  position: absolute; 
  background-color: hsl(203, 35%, 73%, .88);
  border-top-width: 0;
  list-style: none;
  margin-top: 0.2rem;
  max-height: 17rem;
  overflow-y: auto;
  padding-left: 0;
  width: 30rem;
  color: hsl(200, 48%, 25%);
  z-index: 1001;
}
@media (max-width: 600px) {
  .suggestions { 
    max-height: 15rem;
    width: 15rem;
  }
}

.suggestions::-webkit-scrollbar {
  width: 0.3rem;
  height: 0rem;
}


.suggestions::-webkit-scrollbar-thumb {
  background-color: #31708b;
}

.suggestions li {
  padding: 0.5rem;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

@media (max-width: 600px) {
  .suggestions li {
    font-size: 2rem;
  }
}


.suggestion-active {
  background-color: #5085A599;
}

.suggestions li:hover {
  background: #5085a599;
  color: #f7f9fb;
  cursor: pointer;
  font-weight: 700;
}
