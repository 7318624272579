html {
  font-size: 62.5%;
  box-sizing: border-box;
}
@media (max-width: 1860px) {
  html {
    font-size: 59.375%;
  }
}
@media (max-width: 1780px) {
  html {
    font-size: 59.375%;
  }
}
@media (max-width: 1670px) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 1560px) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 1520px) {
  html {
    font-size: 50%;
  }
}
@media (max-width: 1300px) {
  html {
    font-size: 43.75%;
  }
}
@media (max-width: 1020px) {
  html {
    font-size: 43.75%;
  }
}
@media (min-width: 2050px) {
  html {
    font-size: 68.75%;
  }
}
@media (min-width: 2200px) {
  html {
    font-size: 75%;
  }
}
@media (min-width: 2490px) {
  html {
    font-size: 81.25%;
  }
}

body.active-modal {
  overflow-y: hidden;
}

.modal,
.overlay {
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 2;
}

.overlay {
  background: rgba(28, 28, 28, 0.9);
}
.modal-content {
  position: absolute;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: white;
  padding: 2.2rem 2.2rem;
  border-radius: 3px;
  max-width: 90vw;
  background-position: center;
  z-index: 3;
}


.close-modal {
  position: absolute;
  top: 1rem;
  background-color:white;
  right: 1rem;
  padding: 5px 5px;
  border: none;
  font-size: 1.8rem;
  color: #687864;
}
@media (max-width: 1300px) {
  .close-modal {
    top: .4rem;
    right: .4rem;
    background-color: transparent;
    padding: 3px 3px;
  }
}
.close-modal:hover {
  color: #8fc1e3;
  cursor: pointer;
  transition: all 0.2s;
}

.modal-photo {
  display: grid;
  grid-column: 1/1;
  grid-row: 1/1;
  max-height: 80vh;
  margin: 1.5rem;
  max-width: 70vw;
}
@media (max-width: 1300px) {
  .modal-photo {
    margin: 0.5rem;
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 600px) {
  .modal-photo {
    max-width: 85vw;
    margin-bottom: 2rem; 
  }
}

.modal-author {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-left: 2.5rem;
}
@media (max-width: 1300px) {
  .modal-author {
    margin-left: 0.5rem;
  }
}
.modal-author--photo {
  align-self: center;
  width: 4rem;
  height: 4rem;
  border-radius: 10rem;
}
@media (max-width: 600px) {
  .modal-author--photo {
    width: 2.8rem;
    height: 2.8rem;
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.modal-author--description {
  list-style-type: none;
  font-size: 1.6rem;
  font-weight: 400;
  display: flex;
  text-decoration: none;
  color: #687864;
  justify-items: center;
  align-items: center;
  padding: 0;
  margin-right: 2.5rem;
}
@media (max-width: 1300px) {
  .modal-author--description {
    margin-right: 0.5rem;
  }
}
@media (max-width: 600px) {
  .modal-author--description {
    margin-right: 0.5rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 320px) {
  .modal-author--description {
    margin-right: 0.5rem;
    font-size: 1.7rem;
  }
}

li {
  margin-left: .8rem;
}

@media (max-width: 600px) {
  li {
    margin-left: .5rem;
  }
}

.modal-data {
  display: flex;
  align-items: center;
}

.animate-opacity {
  animation: modal-opacity 0.5s;
}

@keyframes modal-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-show {
  animation: show 0.5s;
  animation-fill-mode: forwards;
}

.modal-location {
  font-style: italic;
}
