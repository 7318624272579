.back-to-top {
  position: fixed;
  bottom: -0.4rem;
  right: 0.5rem;
  font-size: 5rem;
  background: transparent;
  color: hsl(203, 35%, 48%, .7);
  cursor: pointer;
  height: 6.5rem;
  width: 6.5rem;
  border: none;
  opacity: 0.6;
  padding-left: 1rem;
  z-index: 1;
}
@media (max-width: 600px) {
  .back-to-top {
    color: hsl(108, 9%, 53%);
    opacity: 0.6;
    right: .6rem;
    height: 7rem;
    width: 7rem;
  }
}

.back-to-top:hover {
  color: hsl(203, 35%, 48%, .8);
  opacity: 0.8;
  transition: all 0.2s;
}
