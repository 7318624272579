.copyright {
  height: 4rem;
  text-align: center;
  background-color: transparent;
  left: 0;
  bottom: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3rem;
  /* color: hsla(198, 48%, 57%, 0.9);   */
  color: #f7f9fb;  
  background: #222;  
  /* background: hsl(210, 33%, 9%); */
  padding-top: 2rem;
  margin-top: .5rem;
  border-top: solid 1px rgb(147, 147, 147, 0.3);
}

.twitter-link {
  text-decoration: underline;
}
.twitter-link:link,
.twitter-link:visited {
  color: #31708b;
  transition: all 0.2s;
}

.twitter-link:hover,
.twitter-link:active {
  color: hsla(198, 48%, 57%);
  text-decoration: none;
  cursor: pointer;
}
.invisible {
  visibility: hidden;
  margin-top: -20rem;
}
