.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url("../../public/abstract-one.webp"); */
  background-image: url("../../public/abstract-four-2560.webp");
  width: 100%;
  height: 100vh !important;
}

.search-container--reduce {
  display: flex;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../public/search-bg.webp");
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 33vh;
  margin-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (max-width: 600px) {
  .search-container--reduce {    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../public/search-bg_1280.jpg");
  }
}
@media (max-width: 600px) {
  .search-form--one {
    margin-top: -25rem;
  }
}
@media (max-width: 600px) {
  .search-form--two {
    /* margin-top: 2rem; */
    margin-top: -5rem;
  }
}

.search-input {
  height: 5rem;
  width: 30vw;
  border-radius: 0.7rem;
  font-size: 2.2rem;
  padding-left: 1rem;
  border: solid 1px rgb(104, 120, 100, 0.4);
  letter-spacing: 0.1rem;
  margin-top: 0.2rem;
}
@media (max-width: 1300px) {
  .search-input {
    width: 40vw;
  }
}
@media (max-width: 1020px) {
  .search-input {
    width: 50vw;
    height: 4rem;
  }
}
@media (max-width: 800px) {
  .search-input {
    width: 55vw;
    height: 4rem;
  }
}
.search-input:active,
:focus {
  outline: none;
  background-color: #f7f9fb;
}

.search-label {
  display: flex;
  font-size: 2.5rem;
  color: #f7f9fb;
  letter-spacing: 0.1rem;
  justify-content: center;
}
@media (max-width: 600px) {
  .search-label {
    font-size: 3rem;
    flex-direction: column;
    margin-bottom: 3rem;
    line-height: 1.2;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.search-label-reversed {
  display: flex;
  font-size: 2.5rem;
  /* color: #31708b; */
  color: #f7f9fb;
  letter-spacing: 0.1rem;
}

@media (max-width: 600px) {
  .search-label-reversed {
    font-size: 2rem;
    flex-direction: column;
    margin-bottom: 1rem;
    line-height: 1.2;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.search-label--viaimages {
  font-weight: 700;
}
.search-label--viaimages-reversed {
  font-weight: 700;
}

@media (max-width: 600px) {
  .search-label--viaimages-reversed {
    font-size: 4.7rem;
    margin-left: -0.8rem;
    font-weight: 700;
  }
}
@media (max-width: 600px) {
  .search-label--viaimages {
    font-weight: 700;
    font-size: 3.4rem;
    margin-left: -0.5rem;
  }
}

.search-bar {
  display: flex;
  align-items: center;
  justify-items: center;
}

.search-btn {
  margin-left: -10rem;
  background-color: transparent;
  border: none;
  outline: none;
}
@media (max-width: 600px) {
  .search-btn {
    margin-left: -8rem;
  }
}

.search-magnifier {
  margin-left: -1rem;
  margin-top: 0.9rem;
  font-size: 3rem;
  color: #687864;
}
.search-magnifier--inactive {
  margin-left: -1rem;
  margin-top: 0.9rem;
  font-size: 3rem;
  color: hsl(108, 9%, 73%);
}

@media (max-width: 600px) {
  .search-magnifier{
    font-size: 3.6rem
  }
}
@media (max-width: 600px) {
  .search-magnifier--inactive{
    font-size: 3.6rem
  }
}
.search-magnifier:hover {
  cursor: pointer;
  /* transform: scale(1.1); */
  color: #8fc1e3;
  transition: all 0.2s;
}

.clear-btn {
  margin-left: -5rem;
  background-color: transparent;
  border: none;
  outline: none;
}

@media (max-width: 600px) {
  .clear-btn {
    margin-left: -4rem;
  }
}

.search-clear {
  margin-left: -1rem;
  margin-top: 0.9rem;
  font-size: 3rem;
  color: #687864;
}

.search-clear--inactive {
  margin-left: -1rem;
  margin-top: 0.9rem;
  font-size: 3rem;
  color: hsl(108, 9%, 73%);
}

@media (max-width: 600px) {
  .search-clear{
    font-size: 3.6rem
  }
}

@media (max-width: 600px) {
  .search-clear--inactive{
    font-size: 3.6rem
  }
}
.search-clear:hover {
  cursor: pointer;
  /* transform: scale(1.1); */
  color: #8fc1e3;
  transition: all 0.2s;
}

@media (max-width: 600px) {
  .search-magnifier:hover {
    color: #687864;
  }
}
@media (max-width: 600px) {
  .search-clear:hover {
    color: #687864;
  }
}

.no-active {
  z-index: -100;
  visibility: hidden;
}

.btn-prev {
  height: 5rem !important;
  width: 5rem;
  /* margin-right: 2.3rem; */
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}
.btn-next {
  height: 5rem !important;
  width: 5rem;
  /* margin-left: 2.3rem; */
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}

.search-line {
  display: flex;
}

.next {
  display: flex;
  font-size: 4rem;
  justify-self: end;
  /* color: #31708b; */
  color: #f7f9fb;
}

.next:hover {
  color: #8fc1e3;
  transition: all 0.2s;
}
@media (max-width: 600px) {
  .next:hover {
    /* color: #31708b; */
    color: #f7f9fb;
    transition: all 0.2s;
  }
}
.prev {
  display: flex;
  font-size: 4rem;
  justify-self: start;
  /* color: #31708b; */
  color: #f7f9fb;
}
.prev:hover {
  color: #8fc1e3;
  transition: all 0.2s;
}
@media (max-width: 600px) {
  .prev:hover {
    /* color: #31708b; */
    color: #f7f9fb;
  }
}

.label-text-container {
  display: flex;
  justify-content: center;
}

.no-images--start {
  position: absolute;
  left: 50%;
  top: 60% !important;
  font-size: 1.8rem;
  transform: translateX(-50%);
  animation-fill-mode: forwards;
  z-index: 10000;
  color: #f7f9fb;
}

@media (max-width: 600px) {
  .no-images--start {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 1.8rem;
    color: #f7f9fb;
  }
}
.no-images {
  position: absolute;
  left: 50vw;

  top: 24%;
  font-size: 1.8rem;
  transform: translateX(-50%);
  animation-fill-mode: forwards;
  /* color:#31708b; */
  color: #f7f9fb;
}

@media (max-width: 660px) {
  .no-images {
    font-size: 1.8rem;
    top: 28%;
  }
}
@media (max-width: 360px) {
  .no-images {
    font-size: 1.7rem;

    top: 32%;
  }
}
@media (max-width: 320px) {
  .no-images {
    font-size: 1.2rem;

    top: 22%;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade--start {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.present-images {
  position: absolute;
  left: 50%;
  top: 16%;
  font-size: 0.1rem;
  transform: translateX(-50%);
}

.message-second-line {
  /* margin-left: 2rem; */
  margin-top: 2.5rem;
  color: #f7f9fb;
}
@media (max-width: 600px) {
  .message-second-line {
    margin-left: 0.5rem;
  }
}
.separate {
  height: 2rem;
 
}


